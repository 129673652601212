@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: var(--font-manrope) !important;
}

.without-ring {
  @apply focus:ring-0 focus:ring-offset-0;
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

/*
* nav height - 96
* footer height -421
* total - 517
*/
.container-height {
  min-height: calc(theme('height.screen') - 517px);
}
.buy-package-height {
  min-height: calc(theme('height.screen') - (102.9px + 95px));
}

.day_today::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) translateY(-8px);
  width: 5px;
  height: 5px;
  background-color: #344054; /* Circle dot color */
  border-radius: 50%;
}

.day_today_selected::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) translateY(-8px);
  width: 5px;
  height: 5px;
  background-color: white; /* Circle dot color */
  border-radius: 50%;
}

.popover-content-width-same-as-its-trigger {
  @apply max-h-[var(--radix-popover-content-available-height)] w-[var(--radix-popover-trigger-width)];
}

/* width */
div::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
div::-webkit-scrollbar-track {
  background: #f0f2f5;
}

/* Handle */
div::-webkit-scrollbar-thumb {
  background: #d0d5dd;
  border-radius: 80px;
}

/* Handle on hover */
div::-webkit-scrollbar-thumb:hover {
  background: #d0d5dd;
}

.room-configuration-mobile-hotel {
  height: calc(100dvh - 75vh);
}

@media (min-height: 670px) {
  .room-configuration-mobile-hotel {
    height: calc(100dvh - 75vh);
  }
}

@media (min-height: 700px) {
  .room-configuration-mobile-hotel {
    height: calc(100dvh - 60vh);
  }
}

@media (max-height: 660px) {
  .room-configuration-mobile-hotel {
    height: calc(100dvh - 75vh);
  }
}
